import utils from 'utils';

import {useContext, useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useQueryClient} from '@tanstack/react-query';
import toast from 'react-hot-toast';

import {ProductBadge, RenderImage, UiButton} from '@components/export';
import {useLabel} from '@/hooks/export';

import {DataGrid, GridColDef, useGridApiRef} from '@mui/x-data-grid';
import {csCZ} from '@mui/x-data-grid/locales';

import appContext from '@/appContext';

import emptyImg from '@img/test1.jpg';

export const ProductsList = (props: {
    allowDisplay: boolean,
}) => {
    const navigate = useNavigate();
    const label = useLabel();
    const queryClient = useQueryClient();
    const apiRef = useGridApiRef();

    const defaultSortModel = [{field: 'Brand', sort: 'asc'}];

    const {searchRes, searchResQuery, sortModel, setSortModel, filters, updateFilters} = useContext(appContext).productsContext;
    const cart = useContext(appContext).cartContext;

    const listPageSize = JSON.parse(localStorage.getItem('listPageSize')) || 12;
    const [paginationModel, setPaginationModel] = useState({pageSize: listPageSize, page: 0});

    const [products, setProducts] = useState([]);
    const [columnsDef, setColumnsDef] = useState([]);

    const [loading, setLoading] = useState(true);
    const [localSortModel, setLocalSortModel] = useState([]);

    const handle = {
        addToCart(id) {
            utils.logEvent('cartEntry', {
                productId: id,
                qty: 1,
            });

            cart.updateItem(id, 1, [])
                .then(() => {
                    queryClient.invalidateQueries({queryKey: ['cart']});

                    toast.remove();
                    toast.success(label.cartIsUpToDate);

                    setLoading(false);
                });
        },
    };

    useEffect(() => {
        setLocalSortModel(sortModel);
    }, [loading]);

    useEffect(() => {
        if (searchResQuery.isFetching) {
            setLoading(true);
        }
    }, [searchResQuery]);

    useEffect(() => {
        setLoading(true);

        if (searchRes?.pages[0]?.filters?.length) {
            setColumnsDef(getColumnsDef(searchRes?.pages[0]?.filters, handle));
        }

        setProducts(searchRes?.pages[0]?.products || []);
        setLoading(false);
    }, [searchRes]);

    useEffect(() => {
        updateFilters({
            offset: (paginationModel.page) * paginationModel.pageSize,
            limit: paginationModel.pageSize,
        });
    }, [paginationModel]);

    useEffect(() => {
        // fixme: this better to be in context
        if (!['name', 'priceCurrent', 'Brand'].includes((sortModel[0].field))) {
            setSortModel(defaultSortModel);

            updateFilters({
                sort: defaultSortModel,
            });
        }

        setPaginationModel({
            ...paginationModel,
            page: 0,
        });
    }, [filters.purpose, filters.attributes]);

    useEffect(() => {
        if (products.length) {
            apiRef.current.autosizeColumns({
                includeHeaders: true,
                includeOutliers: true,
            });
        }
    }, [products]);

    return <div className={cmpClass}>
        {
            <div className={classMap.wrapper}>
                <DataGrid
                    loading={loading || !props.allowDisplay}
                    rows={products.map((product) => {
                        return getProductRow(product);
                    })}
                    apiRef={apiRef}
                    columns={columnsDef}
                    columnHeaderHeight={25}
                    autosizeOptions={{
                        columns: ['name'],
                        includeOutliers: true,
                        includeHeaders: false,
                    }}
                    localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
                    onCellClick={(cell, event) => {
                        if (cell.field === 'name') {
                            utils.navigateCanBeToNewTab(event, '/product/' + cell.id, navigate);
                        }
                    }}
                    sortModel={localSortModel}
                    onSortModelChange={(model) => {
                        const fallbackSortModel = [{
                            field: sortModel[0].field,
                            sort: 'asc',
                        }];

                        if (columnsDef.findIndex((col) => col.field === sortModel[0].field) === -1) {
                            fallbackSortModel[0].field = 'Brand';
                        }

                        setSortModel(!model.length ? fallbackSortModel : model);

                        setPaginationModel({
                            ...paginationModel,
                            page: 0,
                        });

                        updateFilters({
                            sort: !model.length ? fallbackSortModel : model,
                        });
                    }}
                    initialState={{
                        sorting: {sortModel},
                    }}
                    slotProps={{
                        loadingOverlay: {
                            variant: 'skeleton',
                            noRowsVariant: 'skeleton',
                        },
                        columnsManagement: {
                            getTogglableColumns: (columns: GridColDef[]) => {
                                return columns
                                    .filter((col) => !['canAddToCart', 'picture', 'name'].includes(col.field))
                                    .map((column) => column.field);
                            },
                        },
                    }}
                    disableColumnFilter
                    pageSizeOptions={[12, 24, 48]}
                    paginationMode="server"
                    paginationModel={paginationModel}
                    onPaginationModelChange={(e) => {
                        if (!loading) {
                            const newPage = paginationModel.page + (
                                e.page === paginationModel.page ?
                                    0 :
                                    (e.page > paginationModel.page ? 1 : -1)
                            );

                            setPaginationModel({
                                pageSize: e.pageSize,
                                page: e.pageSize !== paginationModel.pageSize ? 0 : newPage,
                            });

                            localStorage.setItem('listPageSize', JSON.stringify(e.pageSize));
                        }
                    }}
                    rowCount={searchRes?.pages[0].foundCount || 1}
                    slots={{
                        noResultsOverlay: () => {
                            return <>
                                {
                                    columnsDef.length ?
                                        <div className={classMap.notFound}>
                                            <p>{label.offerFillMachineRequest}</p>
                                            <UiButton
                                                className={classMap.notFoundBtn}
                                                style={1}
                                                text={label.requestForProduct}
                                                onClick={() => navigate('/machine-request')}
                                            />
                                        </div> : <></>
                                }

                            </>;
                        },
                    }}
                />
            </div>
        }
    </div>;
};

const cmpClass = 'products-list';
const classMap = utils.generateClassMap(cmpClass, {
    wrapper: 'wrapper',
    notFound: 'not-found',
    notFoundBtn: 'not-found-btn',
    listHeader: 'list-header',
    listFieldPrice: 'list-field--price',
    listFieldPicture: 'list-field--picture',
    listFieldName: 'list-field--name',
    listFieldNameDetail: 'list-field--name-detail',
    listFieldStock: 'list-field--stock',
    listFieldOrder: 'list-field--order',
});

const getProductRow = (product: apiProduct) => {
    const row = {};

    row['id'] = product.id;
    row['name'] = product.name;
    row['priceCurrent'] = product.priceCurrent;
    row['stock'] = product.stock;
    row['picture'] = product.images[0] || emptyImg;
    row['canAddToCart'] = !!product.stock && product.priceCurrent;
    row['isDiscount'] = product.isDiscount;
    row['isOnSale'] = product.isOnSale;

    product.attributes.map((attribute: apiFilterOption) => {
        row[attribute.name] = attribute.value;
    });

    return row;
};

const getColumnsDef = (raw: [apiFilterOption], handle) => {
    const list = {};
    const excludedFields = ['Typ_zajisteni', 'Rozmer_pneu', 'Druh_stroje'];

    if (!Array.isArray(raw)) {
        return [];
    }

    list['canAddToCart'] = {
        field: 'canAddToCart',
        headerName: '',
        headerClassName: classMap.listHeader,
        cellClassName: classMap.listFieldOrder,
        width: 50,
        align: 'center',
        renderCell: (cell) => cell.value ?
            <div>
                <i
                    className={'iconfas-cartAdd'}
                    onClick={() => handle.addToCart(cell.id)}
                />
            </div> :
            '',
    };

    list['picture'] = {
        field: 'picture',
        headerName: '',
        headerClassName: classMap.listHeader,
        cellClassName: classMap.listFieldPicture,
        renderCell: (cell) => <RenderImage
            src={cell.value ? utils.getApiImgPath(cell.value, 'thumbnail') : emptyImg}
            alt=""
        />,
        width: 65,
        sortable: false,
    };

    list['name'] = {
        field: 'name',
        headerName: 'Název',
        headerClassName: classMap.listHeader,
        cellClassName: classMap.listFieldName,
        minWidth: 350,
        renderCell: (cell) => <div className={classMap.listFieldNameDetail}>
            <span>{cell.value}</span>
            <ProductBadge isDiscount={cell.row.isDiscount} onSale={cell.row.isOnSale}/>
        </div>,
    };

    list['priceCurrent'] = {
        field: 'priceCurrent',
        headerName: 'Cena',
        headerClassName: classMap.listHeader,
        cellClassName: classMap.listFieldPrice,
        valueFormatter: (value) => utils.formatPrice(value),
        align: 'right',
        minWidth: 100,
    };

    list['stock'] = {
        field: 'stock',
        headerName: 'Sklad',
        headerClassName: classMap.listHeader,
        cellClassName: classMap.listFieldStock,
        renderCell: (cell) => <ProductBadge stock={cell.value} hideDiscount small/>,
        sortable: false,
        minWidth: 140,
    };

    raw.map((item) => {
        if (list[item.name] === undefined && !excludedFields.includes(item.name)) {
            list[item.name] = {
                field: item.name,
                headerName: item.label,
                headerClassName: classMap.listHeader,
            };

            if (item.name === 'Brand') {
                list[item.name].minWidth = 150;
            }
        }
    });

    return Object.entries(list).map((entry: [string, GridColDef]) => {
        return entry[1];
    });
};
