import utils from 'utils';

import {useEffect, useState, useRef} from 'react';

import {useApiInfiniteQuery} from '@/api';

export const useProductsSearch = (props?: {
    viewMode: 'grid' | 'list',
}) => {
    const defaultSortModel = [{field: 'Brand', sort: 'asc'}];
    const [sortModel, setSortModel] = useState(JSON.parse(localStorage.getItem('sortModel')) || defaultSortModel);

    const [filters, setFilters] = useState(utils.parseUrlToFilters());
    const filtersRef = useRef(filters);

    const updateFilters = (newFilters) => {
        filtersRef.current = {
            ...filtersRef.current,
            ...newFilters,
        };

        setFilters(filtersRef.current);
    };

    const query = useApiInfiniteQuery('getProducts', {
        filters,
        keepPreviousData: props.viewMode === 'list',
    });

    useEffect(() => {
        if (filters.offset && props.viewMode === 'grid') {
            query.fetchNextPage();
        }
    }, [filters.offset]);

    useEffect(() => {
        if (!sortModel.length) {
            setSortModel(defaultSortModel);

            return;
        }

        localStorage.setItem('sortModel', JSON.stringify(sortModel));

        updateFilters({
            sort: sortModel,
        });
    }, [sortModel]);

    return {
        filters,
        sortModel,
        setSortModel,
        updateFilters,
        searchRes: query?.data,
        searchResQuery: query,
    };
};
