import {createContext, useState} from 'react';

import {useCurrentUser, useCart, useCatalog, useProductsSearch, useProductsDisplay} from '@/hooks/export';

export const AppContext = createContext<appContext>({});

export const AppContextProvider = (props: any) => {
    // todo: move this to catalogContext
    const [showFilters, setShowFilters] = useState(true);

    const [machine, setMachine] = useState({
        brand: '',
        model: '',
        machineType: '',
        purpose: '',
    });

    const productsDisplay = useProductsDisplay();

    const userContext = {
        ...useCurrentUser(),
    };

    const cartContext = {
        ...useCart({
            enabled: !!userContext.currentUser.id,
        }),
    };

    const catalogContext = {
        ...useCatalog({
            enabled: !!userContext.currentUser.id,
        }),
    };

    const productsContext = {
        machine,
        setMachine,
        showFilters,
        setShowFilters,
        ...useProductsSearch({viewMode: productsDisplay.viewMode}),
        ...productsDisplay,
    };

    const contextValue = {
        productsContext,
        userContext,
        cartContext,
        catalogContext,
    };

    return (
        <AppContext.Provider value={contextValue}>
            {props.children}
        </AppContext.Provider>
    );
};

export default AppContext;
