import {useInfiniteQuery, keepPreviousData} from '@tanstack/react-query';

import {api} from '@/api/index.ts';

export const useApiInfiniteQuery = (
    name: string,
    data: any,
) => {
    const initialPageParam = 0;
    let enabled = false;
    let getNextPageParam = () => 0;
    let getPreviousPageParam = () => 0;
    let queryFn: (key: any) => any;
    let queryKey = [];
    let placeholderData = null;

    switch (name) {
        case 'getProducts': {
            queryKey = ['products', {
                limit: data.filters.limit,
                name: data.filters.name,
                machineType: data.filters.machineType,
                attributes: data.filters.attributes,
                sort: data.filters.sort,
                purpose: data.filters.purpose,
                alternativeTo: data.filters.alternativeTo,
            }];
            queryFn = () => api.products.getMany(data);
            getNextPageParam = () => data.filters.offset + data.filters.limit;
            getPreviousPageParam = () => data.filters.offset - data.filters.limit;
            enabled = !!data.filters.limit;

            if (data.keepPreviousData) {
                placeholderData = keepPreviousData;
                queryKey[1]['offset'] = data.filters.offset;
            }
        }
    }

    return useInfiniteQuery({
        queryKey,
        queryFn,
        initialPageParam,
        getNextPageParam,
        getPreviousPageParam,
        enabled,
        gcTime: 0,
        refetchOnWindowFocus: false,
        placeholderData,
    });
};
