import utils from 'utils';

import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {ProductBadge, RenderImage} from '@components/export';
import {useLabel} from '@/hooks/export';

import emptyImg from '@img/test1.jpg';

export const ProductCard = (props: {
    product: any // todo: product type
}) => {
    const navigate = useNavigate();
    const label = useLabel();

    const product = props.product;

    const [img, setImg] = useState(product.images[0] ? utils.getApiImgPath(product.images[0], 'small') : emptyImg);
    // const [imgThumbnail, setImgThumbnail] = useState(product.images[0] ? utils.getApiImgPath(product.images[0], 'thumbnail') : emptyImg);

    // const imgThumbnail = emptyImg;
    const data = {
        id: '', // todo: product ? product.id : '' ,
        name: '',
        pricePrev: '',
        priceCurrent: '',
        img: emptyImg,
        isDiscount: false,
        currency: 'Kč',
        stock: 0,
        isOnSale: false,
    };

    if (product) {
        data.id = product.id;
        data.name = product.name;
        data.pricePrev = product.pricePrev;
        data.priceCurrent = product.priceCurrent;
        data.isDiscount = product.isDiscount;
        data.stock = product.stock;
        data.isOnSale = product.isOnSale;
    }

    const handle = {
        clickCard: (event) => {
            utils.navigateCanBeToNewTab(event, '/product/' + data.id, navigate);
        },
    };

    // fixme? temp fix
    const flags = {
        isDiscount: data.isDiscount || data.pricePrev !== data.priceCurrent,
    };

    useEffect(() => {
        setImg(utils.getApiImgPath(product.images[0], 'small'));
        // setImgThumbnail(utils.getApiImgPath(product.images[0], 'thumbnail'));
    }, [product.images]);

    return <div className={cmpClass} onClick={(e) => handle.clickCard(e)}>
        <div className={classMap.badgeWrapper}>
            <ProductBadge isDiscount={data.isDiscount} stock={data.stock}/>
            <ProductBadge onSale={data.isOnSale}/>
        </div>

        <RenderImage
            className={classMap.img}
            containerClassName={classMap.imgContainer}
            src={img}
            alt=""
        />

        <span className={classMap.title}>{data.name}</span>
        {/* <ProductBadge isDiscount={data.isDiscount} stock={data.stock}/> todo: keep it for later, may be needed */}
        <div className={classMap.price}>
            <span className={classMap.pricePrev + (!flags.isDiscount ? ' ' + classMap.pricePrevEmpty : '')}>
                {
                    flags.isDiscount ?
                        utils.formatPrice(parseFloat(data.pricePrev)) + ' ' + label.currency :
                        '\u00A0'
                }
            </span>
            <div className={classMap.priceBox}>
                <span className={classMap.priceNoVat}>
                    {label.noVat}
                </span>
                <span className={classMap.priceCurrent}>
                    {utils.formatPrice(parseFloat(data.priceCurrent)) + ' ' + label.currency}
                </span>
            </div>
        </div>
    </div>;
};

const cmpClass = 'product-card';
const classMap = utils.generateClassMap(cmpClass, {
    img: 'img',
    title: 'title',
    price: 'price',
    pricePrev: 'price-prev',
    pricePrevEmpty: 'price-prev--empty',
    priceBox: 'price-box',
    priceNoVat: 'price-no-vat',
    priceCurrent: 'price-current',
    badgeWrapper: 'badge-wrapper',
    imgContainer: 'img-container',
});
