import {useState} from 'react';

export const useProductsDisplay = () => {
    const [viewMode, setViewMode] = useState(JSON.parse(localStorage.getItem('viewMode')) || 'grid');

    return {
        viewMode,
        setViewMode,
    };
};
