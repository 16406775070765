import {useContext, useEffect} from 'react';

import {UiSelect} from '@components/export';
import {useLabel} from '@/hooks/export';

import appContext from '@/appContext';

export const ProductsGridSort = () => {
    const label = useLabel();

    const {filters, updateFilters, sortModel, setSortModel} = useContext(appContext).productsContext;

    const data = {
        // fixme: move to config
        options: [
            {value: 'priceCurrent-asc', label: <span>{label.price} 0-9</span>},
            {value: 'priceCurrent-desc', label: <span>{label.price} 9-0</span>},
            {value: 'Brand-asc', label: <span>{label.manufacturer} A-Z</span>},
            {value: 'Brand-desc', label: <span>{label.manufacturer} Z-A</span>},
        ],
    };

    const handle = {
        setSort(value) {
            const [field, sort] = value.split('-');

            setSortModel([{field, sort}]);
            updateFilters({
                sort: [{field, sort}],
                offset: 0,
            });
        },
    };

    useEffect(() => {
        if (
            !filters.sort ||
            data.options.findIndex((option) => option.value === filters.sort[0].field + '-' + filters.sort[0].sort) === -1
        ) {
            handle.setSort('Brand-asc');
        }
    }, []);

    return <div className={cmpClass}>
        <UiSelect
            name={'productSorting'}
            label={label.doSort}
            value={getSortModelAsOption(sortModel)}
            options={data.options}
            onChange={(value) => {
                handle.setSort(value);
            }}
        />
    </div>;
};

const cmpClass = 'products-grid-sort';

const getSortModelAsOption = (sortModel) => {
    return sortModel[0].field + '-' + sortModel[0].sort;
};
